import { speed } from 'Lib/speed/speed';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import { onScreen } from 'Lib/on-screen/on-screen';
import { eventSender } from 'Lib/event-sender/event-sender';
import { getW } from 'Lib/w/w';
import { eksmoBP } from 'Lib/bp/bp';
import Masonry from 'masonry-layout';
import { removeSkeletonAfterLoading } from '../../site/skeleton-load/skeleton-load';

const $body = $('body');
const cOwl = 'owl-carousel';
const sImg = '.articles__img';
const $articlesSlider = $('.articles_slider');
const $articlesItem = $('.articles__item');

function setListenerImgLoad($images, instanceMasonry) {
    $images.on('eksmoImgLoadSrcOrBg.articles', () => {
        reloadMasonry(instanceMasonry);
        $images.off('eksmoImgLoadSrcOrBg.articles');
    });

    $images.each(function () {
        const image = new Image();

        image.onload = function () {
            reloadMasonry(instanceMasonry);
        };

        image.src = $(this)[0].src;
    });
}

function initMasonry($list) {
    if ($list instanceof jQuery && $list.length) {
        const $curPagenavAjax = $list.closest('.pagenav-ajax');

        $list.each(function () {
            let $img = $(this).find(sImg);
            const instanceMasonry = new Masonry($list[0], {
                transitionDuration: 0,
                stagger: 0,
            });

            onScreen($img, ($pic) => {
                reloadMasonry(instanceMasonry);
                loadSrcOrBg($pic);
            });

            $body.on('eksmoFontLoad', () => {
                reloadMasonry(instanceMasonry);
            });

            $curPagenavAjax.on('eksmoPagenavAjax', () => {
                const $newImg = $list.find(sImg).not($img);

                setListenerImgLoad($newImg, instanceMasonry);
                $img = $img.add($newImg);
            });

            setListenerImgLoad($img, instanceMasonry);
        });
    }
}

function reloadMasonry(instance) {
    setTimeout(() => {
        instance.reloadItems();
        instance.layout();
    }, speed + 10);
}

function preInitMasonry() {
    const $articlesMasonry = $('.articles_masonry');

    if ($articlesMasonry.length) {
        initMasonry($articlesMasonry);
    }
}

function sendEventGA4($el, event) {
    if (!$el.hasClass('articles__item_no-analytics')) {
        eventSender.customDL({
            event: 'eksmo',
            eventAction: event,
            eventCategory: $('title').text().trim(),
            eventLabel: `Онлайн_журнал - ${$el.find('.articles__title').text() || $el.find('.articles__desc').text()}`,
        });
    }
}

function loadImgWithoutMasonry(parent) {
    onScreen($(parent).find(sImg), loadSrcOrBg);
}

function setSlider() {
    if (getW() < eksmoBP.smMax) {
        loadImgWithoutMasonry($articlesSlider);
        $articlesSlider.trigger('destroy.owl.carousel').removeClass(cOwl);
    } else {
        $articlesSlider.trigger('destroy.owl.carousel')
            .removeClass(cOwl)
            .addClass(cOwl)
            .owlCarousel({
                smartSpeed: speed * 2,
                items: 2,
                nav: true,
                dots: false,
                margin: 30,
                loop: true,
                mouseDrag: true,
                navClass: ['articles__arrow-prev arrow arrow_prev arrow_shadow', 'articles__arrow-next arrow arrow_next arrow_shadow'],
                navText: ['', ''],
                autoplayTimeout: 7000,
                autoplayHoverPause: true,
                responsive: {
                    992: { items: 3 },
                    1450: { items: 4 },
                },
                onInitialized(e) {
                    loadImgWithoutMasonry(e.target);
                },
            })
            .on('translate.owl.carousel', (e) => {
                removeSkeletonAfterLoading($(e.target).find('.articles__img'));
            });
    }
}

setSlider();
$body.on('eksmoResizeWidth', setSlider);

onScreen($articlesItem, ($el) => {
    sendEventGA4($el, 'view');
}, 0, 0, 0.8);

$articlesItem.on('click', function () {
    sendEventGA4($(this), 'click');
});

preInitMasonry();
document.addEventListener('init.masonry.eksmo', preInitMasonry);

$body.on('eksmoPagenavAjax', () => {
    preInitMasonry();
});