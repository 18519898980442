import { eksmoBP } from 'Lib/bp/bp';
import { getW } from 'Lib/w/w';
import { speed } from 'Lib/speed/speed';
import { numberEnd } from 'Lib/number-end/number-end';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

const $body = $('body');

function timeToSale(eventDate) {
    const days = 24 * 60 * 60;
    const hours = 60 * 60;
    const minutes = 60;
    let d;
    let h;
    let m;
    // количество дней до события
    let daysToSale = Math.floor(Math.round(eventDate - new Date()) / 1000);

    if (daysToSale < 0) {
        daysToSale = 0;
    }

    // осталось дней
    d = Math.floor(daysToSale / days);
    daysToSale -= d * days;
    // часов
    h = Math.floor(daysToSale / hours);
    daysToSale -= h * hours;
    // минут
    m = Math.floor(daysToSale / minutes);

    if (d < 10) {
        d = `0${d}`;
    }

    if (h < 10) {
        h = `0${h}`;
    }

    if (m < 10) {
        m = `0${m}`;
    }

    return `${d},${h},${m}`;
}

function toggleVisibleTimeNumber($el, isShow) {
    $el.toggleClass('sale__time-number_hidden', !isShow);
}

function init() {
    const sSaleTime = '.sale__time';
    const cSaleTimeLoaded = 'sale__time_loaded';

    $(`${sSaleTime}:not(.${cSaleTimeLoaded})`).each(function () {
        const $th = $(this);
        let timeSale = $th.attr('data-time');
        const isSmall = !!($th.closest('.sale_small'));

        if (isNoEmptyString(timeSale)) {
            const tmp = timeSale.split(' ');
            const date = tmp[0].split('-');
            const time = tmp[1].split(':');
            const $day = $th.find('.sale__time-item:nth-child(1) .sale__time-number');
            const $hour = $th.find('.sale__time-item:nth-child(2) .sale__time-number');
            const $minute = $th.find('.sale__time-item:nth-child(3) .sale__time-number');

            timeSale = new Date(date[0], date[1] - 1, date[2], time[0], time[1]);
            timeSale = timeToSale(timeSale);
            timeSale = timeSale.split(',');
            $th.find('.sale__time-item:nth-child(1) .sale__time-text').text(isSmall ? 'д' : numberEnd(timeSale[0], ['день', 'дня', 'дней']));
            $th.find('.sale__time-item:nth-child(2) .sale__time-text').text(isSmall ? 'ч' : numberEnd(timeSale[1], ['час', 'часа', 'часов']));
            $th.find('.sale__time-item:nth-child(3) .sale__time-text').text(isSmall ? 'мин' : numberEnd(timeSale[2], ['минута', 'минуты', 'минут']));
            $day.first().text(timeSale[0][0]);
            $day.last().text(timeSale[0][1]);
            $hour.first().text(timeSale[1][0]);
            $hour.last().text(timeSale[1][1]);
            $minute.first().text(timeSale[2][0]);
            $minute.last().text(timeSale[2][1]);

            if (isSmall) {
                toggleVisibleTimeNumber($day.first(), true);
                toggleVisibleTimeNumber($hour.first(), true);
                toggleVisibleTimeNumber($minute.first(), true);

                if (parseInt($day.first().text()) === 0) {
                    toggleVisibleTimeNumber($day.first(), false);
                }

                if (parseInt($hour.first().text()) === 0) {
                    toggleVisibleTimeNumber($hour.first(), false);
                }

                if (parseInt($minute.first().text()) === 0) {
                    toggleVisibleTimeNumber($minute.first(), false);
                }
            }
        }

        $th.addClass(cSaleTimeLoaded);
    });
}

init();
$body.on('eksmoPagenavAjax', init);

$body.on('click focus', '.sale__code-copy', function () {
    const $th = $(this);
    const elemVal = $th.prev().first()[0];
    const selection = window.getSelection();
    const range = document.createRange();

    function copyTrue() {
        const $copyText = $th.find('.sale__code-copy-text');

        $copyText.html($copyText.text() === 'промокод скопирован' ? 'копировать промокод' : '<div class="sale__checkmark"></div>промокод скопирован').toggleClass('sale__code-copy-text_orange');

        if (getW() < eksmoBP.smMin) {
            $th.closest('.sale__code').children('.sale__copy-success').toggleClass('sale__copy-success_active');
        }
    }

    range.selectNodeContents(elemVal);
    selection.removeAllRanges();
    selection.addRange(range);

    try {
        if (document.execCommand('copy')) {
            copyTrue();
            setTimeout(copyTrue, speed * 13);
        }
    } catch (err) {
        console.log(`Error: ${err}`);
    }
});