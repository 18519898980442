import { eksmoBP } from 'Lib/bp/bp';
import { speed } from 'Lib/speed/speed';
import { getW } from 'Lib/w/w';
import 'Vendor/owlcarousel/owl.carousel.min';

const sSlider = '.sales_slider';
const $slider = $(`${sSlider} .sales__box`);
const cSliderOn = 'owl-carousel';

function setSlider() {
    $slider.each(function () {
        const $this = $(this);
        const $parent = $this.closest(sSlider);

        $this.trigger('destroy.owl.carousel').removeClass(cSliderOn);

        if ($this.find('.sale').length > 1) {
            $this.addClass(cSliderOn).owlCarousel({
                smartSpeed: speed * 2,
                margin: 15,
                loop: true,
                nav: true,
                items: 1,
                dots: false,
                sArrows: '.sales__arrows',
                navClass: ['sales__prev arrow arrow_prev arrow_shadow', 'sales__next arrow arrow_next arrow_shadow'],
                navText: ['', ''],
                responsive: {
                    480: {
                        items: 2,
                    },
                    770: {
                        margin: 30,
                        items: $parent.hasClass('sales_small') ? 3 : 2,
                        mouseDrag: false,
                    },
                },
            });
        }
    });
}

function fixedSaleLine() {
    const $saleFixed = $('.sale.sale_fixed').first();
    const $wrapperContent = $('.wrapper-content').first();
    let $headerCont = $('.header__bottom').first();
    const $newHeaderCont = $('.cheader').first();

    if (!$headerCont.length) {
        $headerCont = $newHeaderCont;
    }

    if ($saleFixed.length && $wrapperContent.length && $headerCont.length) {
        if (getW() < eksmoBP.mdMin) {
            if (!$saleFixed.closest($wrapperContent).length) {
                const saleFixed = $saleFixed.remove();

                $wrapperContent.append(saleFixed);
                $('.faq-bot').trigger('eksmoCallFaqBotSetClasses');
            }
        } else if (!$saleFixed.closest($headerCont).length) {
            const saleFixed = $saleFixed.remove();

            $headerCont.append(saleFixed);
            $('.faq-bot').trigger('eksmoCallFaqBotSetClasses');
        }
    }
}

fixedSaleLine();
setSlider();
$('body').on('eksmoResizeWidth', fixedSaleLine);